.footer {
    background: #000;
    width: 100%;
    display: flex;
    align-items: center;
}

.linkDiv{
    width: 80vw;
    text-align: center;
    padding-bottom: 1vh;
}

.footerPrivacy{
    width: 19vw;
    text-align: center;
}

.footerText{
    color: white;
    display: inline;
    margin-left: 2vw;
    margin-right: 2vw;
    text-decoration: none;
}

.footerText:hover{
    color: #15cdfc;
}

.quickLinks{
    color: white;
    text-align: center;
    font-size: 24px;
}

.verticalLine {
    border-left: 0.1vw solid;
    height: 8vh;
    border-color: white;
    width: 1vw;
}