* {
    font-family:'sans-serif';
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.homeBackground{
    display: flex;
    height: 90vh;
    width: 100%;
    background-image: url('./images/food.jpg');
    background-size: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 30vh;
}

.homeBackground div{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f56a6a;
    padding: 1vh;
}

.homeTitleText{
    color: white;
}

.aboutBackground{
    display: flex;
    height: 90vh;
    width: 100%;
    background-image: url('./images/cuttingBoard.jpg');
    background-size: 110%;
    justify-content: center;
}

.aboutTitle{
    color: black;
    font-size: 50px;
    margin-left: 10vw;
    margin-top: 5vh;
}

.aboutText{
    color: black;
    font-size: 24px;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 5vh;
}

.resourceBackground{
    justify-content: center;
    text-align: center;
    background-image: url('./images/Sweet Potatoe Fries.jpg');
    background-size: 100%;
    height: 100vh;
    width: 100%;
}

.resourceText{
    color: black;
    font-size: 50px;
    padding-top: 5vh;
}

.resourceProduct{
    display: flex;
    float:left;
    flex-direction: column;
    border-color: blue;
    border-style: solid;
    background-color: white;
    margin-left: 8vw;
    margin-top: 5vh;
    border-radius: 10%;
    height: 32vh;
    width: 22vw;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
}

.resourceIMG{
    height: 27vh;
    width: 22vw;
}

.resourceTitle{
    color: black;
    text-align: center;
    margin-top: 1vh;
}

.contactBackground{
    display: flex;
    width: 100%;
    min-height: 80vh;
    background-image: url('./images/Kale Bowl.jpg');
    background-size: 100%;
    padding-bottom: 5vh;
    justify-content: center;
    text-align: center;
}

.contactContent form{
    background-color: white;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-top: 20px;
}

.contactTitle{
    color: black;
    font-size: 50px;
    text-align: center;
    margin-top: 5vh;
}

.contactText{
    color: black;
    font-size: 24px;
}

.contactContent input, select, textarea{
    display: block;
    width: 100%;
    padding: .5rem .8rem .5rem .8rem;
    margin: .9vw 0 ;
    border: 2px;
    border-color: lightgray;
    border-style: solid;
    border-radius: 5px;
    font-size: 20px;
}

.contactContent textarea{
    font-size: 20px;
}

.contactContent input:focus, textarea:focus{
    outline: none !important;
    border:2px solid rgb(32, 84, 161);
    box-shadow: 0 0 10px #719ECE;
}

.contactContent .submitBtn{
    border: 0;
    background-color: rgb(60, 138, 60);
    color: white;
    width: 20vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 20px;
    border-radius: 10px;
}

.contactContent .Alert{
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    width: 25vw;
}

.privacy{
    margin-right: 5vw;
    margin-left: 5vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.checkboxNews{
    display: flex;
}

.checkbox{
    flex: 1;
}

.acceptStatement{
    margin-top: 15px;
    margin-left: 10px;
}

.resourceForm{
    background-color: white;
    border:2px solid black;
    border-radius: 10px;
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 50vw;
    position: absolute;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.resourceForm input{
    display: block;
    width: 80%;
    margin: auto;
    border: 2px;
    border-color: lightgray;
    border-style: solid;
    border-radius: 5px;
    font-size: 20px;
}

.resourceForm select{
    margin: auto;
    width: 80%;
    font-size: 20px;
}

.resourceForm input:focus, textarea:focus{
    outline: none !important;
    border:2px solid rgb(32, 84, 161);
    box-shadow: 0 0 10px #719ECE;
}

.resourceForm button{
    border: 0;
    background-color: rgb(60, 138, 60);
    color: white;
    width: 20vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    margin-bottom: 5vh;
    font-size: 20px;
    border-radius: 10px;
    margin-top: 20px;
}

.resourceForm p{
    font-size: 20px;
    padding-top: 15px;
}

.x {
    float: right;
    color: red;
    font-size: 20px;
    cursor: pointer;
    
}
